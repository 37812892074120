import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { translations$ } from 'src/app/core/data/data.observables';
import { TimeRegistration } from 'src/app/core/domain/models/time-registration.model';

@Component({
  standalone: true,
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss'],
  imports: [MatDialogModule, MatIconModule, MatButtonModule],
})
export class DeletePopupComponent {
  translations = translations$.value;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { timeRegistration: TimeRegistration },
    public deleteDialogRef: MatDialogRef<DeletePopupComponent>
  ) {}
  onCloseDialog(action: string) {
    this.deleteDialogRef.close(action);
  }
}
