import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  formatDate(inputDate?: string): Date | undefined {
    if (inputDate) {
      const [datePart, timePart] = inputDate.split('T');
      const [year, month, day] = datePart.split('-');
      const [hours, minutes, seconds] = timePart.split(':');
      
      return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
    }
    return undefined;
  }

  parseDate(dateString: string): Date | undefined {
    const dateParts = dateString.split(/[/\s:]/);
    
    if (dateParts.length === 5) {
      const [day, month, year, hours, minutes] = dateParts;
      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));
    }
    return undefined; 
  }

  buildDateTime(timeValue: any, dateValue: any): Date | undefined {
    const dTimeValue = timeValue;
      const [time, meridiem] = dTimeValue.split(' ');
      const [hoursStr, minutesStr] = time.split(':');
      let hours = parseInt(hoursStr, 10);
      
      if (meridiem === 'PM' && hours !== 12) {
        hours += 12;
      }

      const dDateValue = dateValue;
      let dDate;
      
      if (dDateValue instanceof Date) {
        dDate = dDateValue;
      } else {
        dDate = new Date(dDateValue);
      }
      
      const dDateTime = new Date(dDate);
      dDateTime.setHours(hours);
      dDateTime.setMinutes(parseInt(minutesStr, 10));

      return dDateTime;
  }

  formatTime(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    
    return `${hours}:${formattedMinutes}`;
  }
}