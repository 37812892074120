import { Action, createReducer, on } from '@ngrx/store';
import { GroupUser } from 'processdelight-angular-components';
import { AppState } from 'src/app/app.reducer';
import { getGroupUsersResolved } from './group.actions';

export const featureSlice = 'group';

export interface State {
    groups: GroupUser[];
}
  
  const defaultState: State = {
    groups: []
};

export function Reducer(state: State | undefined, action: Action) {
  return groupReducer(state, action);
}

export const initialState: State = defaultState;
export const groupReducer = createReducer(
  initialState,
  on(getGroupUsersResolved, (state, { groupUsers }) => ({
    ...state,
    groups: [...groupUsers],
  })) 
);

export const groupState = (state: AppState) => state.coreFeature.group;