import { BaseEntity } from 'processdelight-angular-components';

export class Language extends BaseEntity {
  title!: string;
  code?: string;

  constructor(obj: Partial<Language>) {
    super(obj);
    Object.assign(this, obj);
  }
}
