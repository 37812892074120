import { createAction, props } from "@ngrx/store";
import { Skill } from "../../domain/models/skill.model";

export const getSkills = createAction(
    '[Skill] Get skills',
    props<{
        callback?: () => void;
    }>()
);

export const getSkillsResolved = createAction(
    '[Skill] Get skills resolved',
    props<{
        result: Skill[];
    }>()
);