import { Action, createReducer, on } from "@ngrx/store";
import { Skill } from "../../domain/models/skill.model";
import { getSkillsResolved } from "./skill.actions";
import { AppState } from "src/app/app.reducer";

export const featureSlice = 'skill';

export interface State {
    skills: Skill[];
    skill?: Skill;
    ishtarSkillIds?: string[];
}

const defaultState: State = {
    skills: [],
    skill: undefined,
    ishtarSkillIds: [],
}

export function Reducer(state: State | undefined, action: Action) {
    return skillReducer(state, action);
}

export const initialState: State = defaultState;

export const skillReducer = createReducer(
    initialState,
    on(getSkillsResolved, (state, { result }) => ({
        ...state,
        skills: [...result],
    }))
);

export const skillState = (state: AppState) => state.coreFeature.skill;