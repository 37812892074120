import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, of, switchMap, tap } from "rxjs";
import { ApiService } from "../../services/api.service";
import { getGroupUsers, getGroupUsersResolved } from "./group.actions";

@Injectable({ providedIn: 'root' })
export class GroupEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  getGroups = createEffect(() =>
    this.actions$.pipe(
    ofType(getGroupUsers),
      switchMap(({ callback }) =>
          this.apiService.getGroups().pipe(
          switchMap((groupUsers) => of(getGroupUsersResolved({ groupUsers }))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
          )
      )
    )
  );
}