import { BaseEntity, GroupUser } from 'processdelight-angular-components';
import { DateTime } from 'luxon';
import { DummyProject } from './dummy-project.model';

export class TimeRegistration extends BaseEntity {
  title?: string;
  startDate?: DateTime;
  endDate?: DateTime;
  user?: GroupUser;
  projectId?: string | null = null;
  dummyProject?: DummyProject | null = null;
  timeSortId!: string;
  taskId?: string | null = null;
  skillId?: string | null = null;

  constructor(obj: Partial<TimeRegistration>) {
    super(obj);
    Object.assign(this, obj);
  }
}
