import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addTimeSort,
  addTimeSortResolved,
  getTimeSorts,
  getTimeSortsResolved,
  getUserSkills,
  getUserSkillsResolved,
  removeTimeSorts,
  removeTimeSortsResolved,
  updateTimeSort,
  updateTimeSortResolved,
} from './time-sort.actions';
import { catchError, of, switchMap, tap } from 'rxjs';
import { ApiService } from '../../services/api.service';

@Injectable({ providedIn: 'root' })
export class TimeSortEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService
  ) {}

  getUserSkills = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSkills),
      switchMap(({ userId, callback }) =>
        this.apiService.getUserSkills(userId).pipe(
          switchMap((result) =>
            of(
              getUserSkillsResolved({
                userId,
                result,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getTimeSorts = createEffect(() =>
    this.actions$.pipe(
      ofType(getTimeSorts),
      switchMap(({ callback }) =>
        this.apiService.getTimeSorts().pipe(
          switchMap((result) =>
            of(
              getTimeSortsResolved({
                result,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addTimeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(addTimeSort),
      switchMap(({ timeSorts }) =>
        this.apiService.addTimeSort(timeSorts).pipe(
          switchMap((addedTimeSorts) =>
            of(addTimeSortResolved({ addedTimeSorts }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  updateTimeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTimeSort),
      switchMap(({ timeSorts }) =>
        this.apiService.updateTimeSort(timeSorts).pipe(
          switchMap((updatedTimeSorts) =>
            of(updateTimeSortResolved({ updatedTimeSorts }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  removeTimeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTimeSorts),
      switchMap(({ ishtarTimeTimesortIds, callback }) =>
        this.apiService.removeTimeSorts(ishtarTimeTimesortIds).pipe(
          switchMap(() =>
            of(removeTimeSortsResolved({ ishtarTimeTimesortIds }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}