import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Subject, map } from "rxjs";
import { AppState } from "src/app/app.reducer";
import { calendarItemState } from "./calendar-item.reducer";
import { getCalendarItems } from "./calendar-item.actions";
import { Filter } from "processdelight-angular-components";

@Injectable({
    providedIn: 'root',
})

export class CalendarItemFacade {
    calendarItems$ = (userId: string) =>
    this._store.pipe(
      select(calendarItemState),
      map((state) => state.calendarItems[userId])
    );

    constructor(private _store: Store<AppState>) {}

    getCalendarItems$(
        userId: string,
        filters: Filter[]
    ) {
        const subject = new Subject<void>();
        this._store.dispatch(
            getCalendarItems({
                userId,
                filters,
                callback: () => {
                    subject.next();
                    subject.complete();
                }
            })
        );
        return subject.asObservable();
    }
}