<processdelight-top-layout
  [sideBarActions]="actions"
  [iconActions]="iconActions"
  [navColor]="(navColor$ | async) || '#124464'"
  [navContrast]="(navContrast$ | async) || '#fff'"
  [actionBarTitleHidden]="true"
  [sideBarButtonEnabled]="true"
  [appLogo]="appLogo"
  [appName]="title"
>
  <router-outlet></router-outlet
></processdelight-top-layout>
