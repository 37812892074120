import { BaseEntity } from 'processdelight-angular-components';
import { Skill } from './skill.model';
import { TimeSortType } from '../enums/time-sort-type';

export class TimeSort extends BaseEntity {
  sort!: string;
  color?: string;
  type!: TimeSortType; 
  skills?: Skill[] = [];

  constructor(obj: Partial<TimeSort>) {
    super(obj);
    Object.assign(this, obj);
  }
}
