import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { timeSortState } from './time-sort.reducer';
import { Subject, map } from 'rxjs';
import {
  addTimeSort,
  getTimeSorts,
  getUserSkills,
  removeTimeSorts,
  updateTimeSort,
} from './time-sort.actions';
import { TimeSort } from '../../domain/models/time-sort';

@Injectable({
  providedIn: 'root',
})
export class TimeSortFacade {
  timeSorts$ = this._store.pipe(
    select(timeSortState),
    map((state) => state.timeSorts)
  );

  pagingCookie$ = this._store.pipe(
    select(timeSortState),
    map((state) => state.pagingCookie)
  );

  totalRecordCount$ = this._store.pipe(
    select(timeSortState),
    map((state) => state.totalRecordCount)
  );

  userSkills$ = (userId: string) => this._store.pipe(
    select(timeSortState),
    map((state) => state.userSkills[userId])
  );

  private loadedUserSkills: Set<string> = new Set();

  constructor(private _store: Store<AppState>) {}

  getUserSkill$(userId: string) {
    const subject = new Subject<void>();
    if (this.loadedUserSkills.has(userId)) {
      subject.complete();
    } else {
      this._store.dispatch(
        getUserSkills({
          userId,
          callback: () => {
            this.loadedUserSkills.add(userId);
            subject.next();
            subject.complete();
          },
        })
      );
    }
    return subject.asObservable();
  }

  getTimeSorts$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getTimeSorts({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addTimeSort(timeSorts: TimeSort[]) {
    this._store.dispatch(addTimeSort({ timeSorts }));
  }

  updateTimeSort(timeSorts: TimeSort[]) {
    this._store.dispatch(updateTimeSort({ timeSorts }));
  }

  removeTimeSorts(ishtarTimeTimesortIds: string[]) {
    const subject = new Subject<void>();
    this._store.dispatch(
      removeTimeSorts({
        ishtarTimeTimesortIds,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }
}