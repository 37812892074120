import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";
import { TimeSortEffects } from "./time-sort/time-sort.effects";
import * as fromTimeSort from './time-sort/time-sort.reducer';
import * as fromSkill from './skill/skill.reducer';
import * as fromDummyProject from './dummy-project/dummy-project.reducer';
import * as fromCalendarItem from './calendar-item/calendar-item.reducer';
import * as fromTimeRegistration from './time-registration/time-registration.reducer';
import * as fromGroup from './group/group.reducer';
import { SkillEffects } from "./skill/skill.effects";
import { DummyProjectEffects } from "./dummy-project/dummy-project.effects";
import { CalendarItemEffects } from "./calendar-item/calendar-item.effects";
import { TimeRegistrationEffects } from "./time-registration/time-registration.effects";
import { GroupEffects } from "./group/group.effects";

export const featureKey = 'coreFeature';

export interface CoreState {
    [fromTimeSort.featureSlice]: fromTimeSort.State;
    [fromSkill.featureSlice]: fromSkill.State;
    [fromDummyProject.featureSlice]: fromDummyProject.State;
    [fromCalendarItem.featureSlice]: fromCalendarItem.State;
    [fromTimeRegistration.featureSlice]: fromTimeRegistration.State;
    [fromGroup.featureSlice]: fromGroup.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
    featureKey,
    {
        factory: () => ({
            [fromTimeSort.featureSlice]: fromTimeSort.Reducer,
            [fromSkill.featureSlice]: fromSkill.Reducer,
            [fromDummyProject.featureSlice]: fromDummyProject.Reducer,
            [fromCalendarItem.featureSlice]: fromCalendarItem.Reducer,
            [fromTimeRegistration.featureSlice]: fromTimeRegistration.Reducer,
            [fromGroup.featureSlice]: fromGroup.Reducer
        })
    }
);

export const effects = [
    TimeSortEffects,
    SkillEffects,
    DummyProjectEffects,
    CalendarItemEffects,
    TimeRegistrationEffects,
    GroupEffects
];