import { createAction, props } from "@ngrx/store";
import { TeamCalendarItem } from "../../domain/models/team-calendar-item";
import { Filter } from "processdelight-angular-components";

export const getCalendarItems = createAction(
    '[Calendar item] Get calendar items',
    props<{
        userId: string,
        filters: Filter[];
        callback?: () => void;
    }>()
);

export const getCalendarItemsResolved = createAction(
    '[Calendar item] Get calendar items resolved',
    props<{
        userId: string,
        result: TeamCalendarItem[];
    }>()
);