import { createAction, props } from "@ngrx/store";
import { GroupUser } from "processdelight-angular-components";

export const getGroupUsers = createAction(
  '[Group] Get groups and users',
  props<{ callback?: () => void }>()
);
export const getGroupUsersResolved = createAction(
  '[Group] Get groups and users resolved',
  props<{ groupUsers: GroupUser[] }>()
);