import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Subject, map } from "rxjs";
import { AppState } from "src/app/app.reducer";
import { getGroupUsers } from "./group.actions";
import { groupState } from "./group.reducer";

@Injectable({
    providedIn: 'root',
  })
  export class GroupFacade {
    groups$ = this._store.pipe(
      select(groupState),
      map((state) => state.groups)
  )

  constructor(private _store: Store<AppState>) {}

  getGroups$(
  ) {
      const subject = new Subject<void>();
      this._store.dispatch(
          getGroupUsers({
              callback: () => {
                  subject.next();
                  subject.complete();
              }
          })
      );
      return subject.asObservable();
    }
  }