import { BaseEntity } from 'processdelight-angular-components';
import { DateTime } from 'luxon';
import { Client } from './client';
import { GroupUserEntity } from './group-user-entity';

export class Project extends BaseEntity {
  projectName?: string;
  projectId?: string;
  parentId?: string;
  clientId?: string;
  client?: Client;
  statusId?: string;
  description?: string;
  preStudy?: boolean;
  calculateProgress?: boolean;
  isTemplate?: boolean;
  startDate?: DateTime;
  deadline?: DateTime;
  projectTypeId?: string;
  projectVersion?: string;
  projectChannel?: string;
  progress?: number;
  productOwnerId?: string;
  owners?: GroupUserEntity[];
  members?: GroupUserEntity[];
  //discounts?: Discount[];

  constructor(obj: Partial<Project>) {
    super(obj);
    Object.assign(this, obj);
  }
}
