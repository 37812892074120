import { DateTime } from 'luxon';

export class PublicHolidayModel {
  holidayType?: string;
  holidayDate?: DateTime;

  constructor(obj: Partial<PublicHolidayModel>) {
    Object.assign(this, obj);
  }
}
