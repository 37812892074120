import { BaseEntity } from 'processdelight-angular-components';

export class DummyProject extends BaseEntity {
  projectNumber!: number;
  title?: string;
  description?: string;

  constructor(obj: Partial<DummyProject>) {
    super(obj);
    Object.assign(this, obj);
  }
}
