<div class="wrapper d-flex flex-column h-100">
  <div class="row border-bottom">
    <div  class="col-md-6">
      <form *ngIf="isManager" [formGroup]="userForm" class="topRowContainer sticky-top flex-shrink-0 user-form" >
        <mat-form-field (click)="onUsersDropdownClick()" class="clickable users" appearance="outline">
          <mat-label class="clickable">{{ getTranslation$("users") | async }}</mat-label>
          <mat-select appMatSelectLetSpace formControlName="user" (selectionChange)="onUserChange($event)"
              (openedChange)="userControl.patchValue('')" panelClass="dropdown-panel">
              <input #userDropdown [formControl]="userControl" type="text" name="filter-options"
                  id="filter-options" [placeholder]="(getTranslation$('searchUser') | async)" class="searchBar" autocomplete="off" />
                  <div class="scrollbar">
                      <ng-container *ngIf="userControlVal">
                          <mat-option
                              [value]="userFormControl.value"
                              >{{ getUserValue(userControlVal) }}
                        </mat-option>
                      </ng-container>
                      <mat-option *ngFor="let user of filteredUsers | async" [value]="user.id">
                          {{ user.displayName }}
                      </mat-option>
                  </div>
              </mat-select>
          </mat-form-field>
      </form>
    </div>
    <div class="total-hours col-md-6 text-end mr-3">
      <div class="px-3">
        Total Hours: {{totalAmountOfHours}}
      </div>
    </div>
  </div>
  <processdelight-calendar
      #kalender
      [calenderItems]="calenderItems"
      (dateChanged)="dateChanged($event)"
      (addCalenderItem)="addItem($event)"
      (calenderItemDragOrResize)="itemChangedByDragOrResize($event)"
      (viewChanged)="onViewChanged($event)"
      [hideTopBar]="hideTopBar"
      [allowItemCollision]="allowItemCollision"
      [contextBlockMenuActionsFn]="contextBlockMenuActionsFn"
      [contextItemMenuActionsFn]="contextItemMenuActionsFn"
      [customContextMenu]="true"
      [calendarHeight]="calendarHeight"
      [hourBlocks]="hourBlocks"
      [currentView]="currentView"
      [selectedDate]="navDate"
      [splitMultipleDayEventsWeekView]="true"
  ></processdelight-calendar>
</div>
