import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { DummyProject } from '../../domain/models/dummy-project.model';
import {
  addDummyProjectResolved,
  getDummyProjectsResolved,
  removeDummyProjectsResolved,
  updateDummyProjectResolved,
} from './dummy-project.actions';

export const featureSlice = 'dummyProject';

export interface State {
  dummyProjects: DummyProject[];
  dummyProject?: DummyProject;
  ishtarTimeDummyProjectIds?: string[];
}

const defaultState: State = {
  dummyProjects: [],
  dummyProject: undefined,
  ishtarTimeDummyProjectIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return dummyProjectReducer(state, action);
}

export const initialState: State = defaultState;

export const dummyProjectReducer = createReducer(
  initialState,
  on(getDummyProjectsResolved, (state, { result }) => ({
    ...state,
    dummyProjects: [...result],
  })),
  on(removeDummyProjectsResolved, (state, { ishtarTimeDummyProjectIds }) => {
    return {
      ...state,
      dummyProjects: state.dummyProjects.filter(
        (d) => !ishtarTimeDummyProjectIds.includes(d.id!)
      ),
    };
  }),
  on(updateDummyProjectResolved, (state, { updatedDummyProjects }) => ({
    ...state,
    dummyProjects: state.dummyProjects?.map(
      (t) => updatedDummyProjects.find((s) => t.id === s.id) ?? t
    ),
  })),
  on(addDummyProjectResolved, (state, { addedDummyProjects }) => ({
    ...state,
    dummyProjects: addedDummyProjects.concat([...(state.dummyProjects ?? [])]),
  }))
);

export const dummyProjectState = (state: AppState) =>
  state.coreFeature.dummyProject;
