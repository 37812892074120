import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appTimeInput]'
})
export class TimeInputDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 4);

    if (inputValue.length >= 3) {
      inputValue = inputValue.replace(/(\d{2})(\d{2})/, '$1:$2');
    }

    inputElement.value = inputValue;
  }
}