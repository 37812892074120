import { BaseEntity, GroupUser } from 'processdelight-angular-components';
import { DateTime } from 'luxon';
import { VacationType } from './vacationType';

export class TeamCalendarItem extends BaseEntity {
  approvalStatus?: any;
  approver?: GroupUser;
  users?: GroupUser[] = [];
  endDate?: DateTime;
  rejectReason?: string;
  requester?: GroupUser;
  startDate?: DateTime;
  vacationReason?: string;
  vacationType?: VacationType;

  constructor(obj: Partial<TeamCalendarItem>) {
    super(obj);
    Object.assign(this, obj);
  }
}
