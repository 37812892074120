import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getSkills, getSkillsResolved } from "./skill.actions";
import { catchError, of, switchMap, tap } from "rxjs";
import { ApiService } from "../../services/api.service";

@Injectable({ providedIn: 'root' })

export class SkillEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService
  ) {}

  getSkills = createEffect(() =>
        this.actions$.pipe(
        ofType(getSkills),
        switchMap(({ callback }) =>
            this.apiService.getSkills().pipe(
            switchMap((result) => of(getSkillsResolved({ result }))),
            tap(() => (callback ? callback() : undefined)),
            catchError((e) => [])
            )
        )
      )
   );
}