export const CalendarColors = {
    Green: '#008000',
    Orange: '#ebdab8',
    Red: '#FF2211',
    Blue: '#0000FF',
    White: '#FFFFFF',
    LightGray: '#D3D3D3'
};

export const CalendarViewType = {
    Week: 'week',
    Day: 'day',
    Month: 'month'
}

export const MY_FORMATS = {
    display: {
      dateInput: 'dd/MM/yyyy',
      monthYearLabel: 'MMM yyyy',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM yyyy',
    },
};