import { Routes } from "@angular/router";
import { CalendarIshtarTimeComponent } from "./calendar/calendar.component";
import { MicrosoftAuthenticationGuard } from "processdelight-angular-components";

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('processdelight-angular-components').then((m) => m.MsalModule),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./core/components/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
  {
    path: 'home',
    canActivate: [MicrosoftAuthenticationGuard],
    component: CalendarIshtarTimeComponent,
    data: { breadcrumbTitle: 'My time registrations' }
  },
  {
    path: 'settings',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () => import('./configuration/settings.component'),
    loadChildren: () =>
      import('./configuration/settings.component').then(
        (settings) => settings.settingsRoutes
      ),
    data: { breadcrumbTitle: 'Settings' }
  },
  {
    path: '**',
    redirectTo: '',
  },
];