import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/app.reducer";
import { Observable, Subject, map } from "rxjs";
import { timeRegistrationState } from "./time-registration.reducer";
import { 
    addTimeRegistration, 
    getTimeRegistrations, 
    removeTimeRegistrations, 
    updateTimeRegistration } 
from "./time-registration.actions";
import { Filter } from "processdelight-angular-components";
import { TimeRegistration } from "../../domain/models/time-registration.model";

@Injectable({
    providedIn: 'root',
})
export class TimeRegistrationFacade {
    timeRegistrations$ = (userId: string) => 
      this._store.pipe(
        select(timeRegistrationState),
        map((state) => state.timeRegistrations[userId] )
    );

    constructor(private _store: Store<AppState>) {}

    getTimeRegistrations$(userId: string, filters: Filter[]) {
        const subject = new Subject<void>();
        this._store.dispatch(
            getTimeRegistrations({
                userId,
                filters,
                callback: () => {
                    subject.next();
                    subject.complete();
                }
            })
        );
        return subject.asObservable();
    }

    addTimeRegistration$(
        timeRegistrations: TimeRegistration[]
    ): Observable<TimeRegistration[]> {
        const subject = new Subject<TimeRegistration[]>();

        this._store.dispatch(
            addTimeRegistration({ 
                timeRegistrations,
                callback: (timeRegistrations) => {
                    subject.next(timeRegistrations);
                    subject.complete();
                }
            })
        );
        return subject.asObservable();
    }

    updateTimeRegistration$(
        timeRegistrations: TimeRegistration[]
    ): Observable<TimeRegistration[]> {
        const subject = new Subject<TimeRegistration[]>();
        this._store.dispatch(
            updateTimeRegistration({ 
                timeRegistrations,
                callback: (timeRegistrations) => {
                    subject.next(timeRegistrations);
                    subject.complete();
                }
            })
        );
        return subject.asObservable();
    }

    removeTimeRegistration(ishtarTimeRegistrationIds: string[]) {
        const subject = new Subject<void>();
        this._store.dispatch(
            removeTimeRegistrations({
                ishtarTimeRegistrationIds,
                callback: () => {
                subject.next();
                subject.complete();
                },
            })
        );
        return subject.asObservable();
    }
}