import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/app.reducer";
import { Subject, map } from "rxjs";
import { dummyProjectState } from "./dummy-project.reducer";
import { addDummyProject, getDummyProjects, removeDummyProjects, updateDummyProject } from "./dummy-project.actions";
import { DummyProject } from "../../domain/models/dummy-project.model";

@Injectable({
    providedIn: 'root',
})
export class DummyProjectFacade {
    dummyProjects$ = this._store.pipe(
        select(dummyProjectState),
        map((state) => state.dummyProjects)
    );

    constructor(private _store: Store<AppState>) {}

    getDummyProjects$() {
        const subject = new Subject<void>();
        this._store.dispatch(
            getDummyProjects({
                callback: () => {
                    subject.next();
                    subject.complete();
                }
            })
        );
        return subject.asObservable();
    }

    addDummyProject(
        dummyProjects: DummyProject[]
    ) {
        this._store.dispatch(
            addDummyProject({ dummyProjects })
        );
    }

    updateDummyProject(
        dummyProjects: DummyProject[]
    ) {
        this._store.dispatch(
            updateDummyProject({ dummyProjects })
        );
    }

    removeDummyProjects(ishtarTimeDummyProjectIds: string[]) {
        const subject = new Subject<void>();
        this._store.dispatch(
            removeDummyProjects({
                ishtarTimeDummyProjectIds,
                callback: () => {
                subject.next();
                subject.complete();
                },
            })
        );
        return subject.asObservable();
    }
}