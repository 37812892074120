import { Action, createReducer, on } from "@ngrx/store";
import { AppState } from "src/app/app.reducer";
import { TeamCalendarItem } from "../../domain/models/team-calendar-item";
import { getCalendarItemsResolved } from "./calendar-item.actions";

export const featureSlice = 'calendarItem';

export interface State {
    calendarItems: { [userId: string]: TeamCalendarItem[] };
}

const defaultState: State = {
    calendarItems: {}
}

export function Reducer(state: State | undefined, action: Action) {
    return calendarItemReducer(state, action);
}

export const initialState: State = defaultState;

export const calendarItemReducer = createReducer(
    initialState,
    on(getCalendarItemsResolved, (state, { userId, result }) => ({
        ...state,
        calendarItems: {
            ...state.calendarItems,
            [userId]: result,
        }
    }))
);

export const calendarItemState = (state: AppState) => state.coreFeature.calendarItem;