import { createAction, props } from "@ngrx/store";
import { DummyProject } from "../../domain/models/dummy-project.model";

export const getDummyProjects = createAction(
    '[Dummy Project] Get dummy projects',
    props<{
        callback?: () => void;
    }>()
);

export const getDummyProjectsResolved = createAction(
    '[Dummy Project] Get dummy projects resolved',
    props<{
        result: DummyProject[];
    }>()
);

export const removeDummyProjects = createAction(
    '[Dummy Project] Remove dummy project',
    props<{ 
        ishtarTimeDummyProjectIds: string[]; 
        callback?: () => void;
    }>()
);

export const removeDummyProjectsResolved = createAction(
    '[Dummy Project] Remove dummy project resolved',
    props<{ ishtarTimeDummyProjectIds: string[] }>()
);

export const updateDummyProject = createAction(
    '[Dummy Project] Update dummy project',
    props<{ dummyProjects: DummyProject[] }>()
);

export const updateDummyProjectResolved = createAction(
    '[Dummy Project] Update dummy project resolved',
    props<{ updatedDummyProjects: DummyProject[] }>()
);

export const addDummyProject = createAction(
    '[Dummy Project] Add dummy project',
    props<{
        dummyProjects: DummyProject[];
    }>()
);

export const addDummyProjectResolved = createAction(
    '[Dummy Project] Add dummy project resolved',
    props<{ addedDummyProjects: DummyProject[] }>()
);