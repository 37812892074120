import { createAction, props } from "@ngrx/store";
import { TimeRegistration } from "../../domain/models/time-registration.model";
import { Filter } from "processdelight-angular-components";

export const getTimeRegistrations = createAction(
    '[TimeRegistration] Get time registrations',
    props<{
        userId: string,
        filters: Filter[];
        callback?: () => void;
    }>()
);

export const getTimeRegistrationsResolved = createAction(
    '[TimeRegistration] Get time registrations resolved',
    props<{
        userId: string,
        result: TimeRegistration[];
    }>()
);

export const removeTimeRegistrations = createAction(
    '[TimeRegistration] Remove time registrations',
    props<{ 
        ishtarTimeRegistrationIds: string[]; 
        callback?: () => void 
    }>()
);

export const removeTimeRegistrationsResolved = createAction(
    '[TimeRegistration] Remove time registration resolved',
    props<{ ishtarTimeRegistrationIds: string[] }>()
);

export const updateTimeRegistration = createAction(
    '[TimeRegistration] Update time registration',
    props<{
        timeRegistrations: TimeRegistration[];
        callback?: (timeRegistrations: TimeRegistration[]) => void;
    }>()
);

export const updateTimeRegistrationResolved = createAction(
    '[TimeRegistration] Update time registration resolved',
    props<{updatedTimeRegistrations: TimeRegistration[] }>()
);

export const updateTimeRegistrationFailed = createAction(
    '[TimeRegistration] Update time registration failed',
    props<{ error: any }>()
  );

export const addTimeRegistration = createAction(
    '[TimeRegistration] Add time registration',
    props<{
        timeRegistrations: TimeRegistration[];
        callback?: (timeRegistrations: TimeRegistration[]) => void;
    }>()
);

export const addTimeRegistrationResolved = createAction(
    '[TimeRegistration] Add time registration resolved',
    props<{addedTimeRegistrations: TimeRegistration[] }>()
);