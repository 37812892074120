import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { TimeRegistration } from '../../domain/models/time-registration.model';
import {
  addTimeRegistrationResolved,
  getTimeRegistrationsResolved,
  removeTimeRegistrationsResolved,
  updateTimeRegistrationFailed,
  updateTimeRegistrationResolved,
} from './time-registration.actions';

export const featureSlice = 'timeRegistration';

export interface State {
  timeRegistrations: { [userId: string]: TimeRegistration[] };
  ishtarTimeRegistrationIds?: string[];
}

const defaultState: State = {
  timeRegistrations: {},
  ishtarTimeRegistrationIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return timeRegistrationReducer(state, action);
}

export const initialState: State = defaultState;

export const timeRegistrationReducer = createReducer(
  initialState,
  on(getTimeRegistrationsResolved, (state, { userId, result }) => ({
    ...state,
    timeRegistrations: {
      ...state.timeRegistrations,
      [userId]: result,
    },
  })),
  on(
    removeTimeRegistrationsResolved,
    (state, { ishtarTimeRegistrationIds }) => {
      const updatedTimeRegistrations = { ...state.timeRegistrations };

      for (const userId in updatedTimeRegistrations) {
        if (
          Object.prototype.hasOwnProperty.call(updatedTimeRegistrations, userId)
        ) {
          updatedTimeRegistrations[userId] = updatedTimeRegistrations[
            userId
          ].filter((t) => !ishtarTimeRegistrationIds.includes(t.id!));
        }
      }

      return {
        ...state,
        timeRegistrations: updatedTimeRegistrations,
      };
    }
  ),
  on(updateTimeRegistrationResolved, (state, { updatedTimeRegistrations }) => {
    const updatedTimeRegistrationsMap = { ...state.timeRegistrations };

    for (const userId in updatedTimeRegistrationsMap) {
      if (
        Object.prototype.hasOwnProperty.call(
          updatedTimeRegistrationsMap,
          userId
        )
      ) {
        updatedTimeRegistrationsMap[userId] = updatedTimeRegistrationsMap[
          userId
        ].map((t) => updatedTimeRegistrations.find((s) => t.id === s.id) ?? t);
      }
    }

    return {
      ...state,
      timeRegistrations: updatedTimeRegistrationsMap,
    };
  }),
  on(updateTimeRegistrationFailed, (state, { error }) => ({
    ...state,
    error,
  })),
  on(addTimeRegistrationResolved, (state, { addedTimeRegistrations }) => {
    const updatedTimeRegistrations = { ...state.timeRegistrations };

    for (const timeRegistration of addedTimeRegistrations) {
      const userId = timeRegistration.user?.user?.id;

      if (userId) {
        updatedTimeRegistrations[userId] = [
          ...(updatedTimeRegistrations[userId] ?? []),
          timeRegistration,
        ];
      }
    }

    return {
      ...state,
      timeRegistrations: updatedTimeRegistrations,
    };
  })
);

export const timeRegistrationState = (state: AppState) =>
  state.coreFeature.timeRegistration;
