<div class="dialogContainer">
  <div class="text-end">
    <h1 mat-dialog-title class="col-8 text-center">
      {{
        editMode
          ? (getTranslation$("editRegistration") | async)
          : copyMode
          ? (getTranslation$("copyTimeRegistration") | async)
          : (getTranslation$("newRegistration") | async)
      }}
    </h1>
    <mat-icon class="closeButtonIcon" (click)="onCloseDialog()">close</mat-icon>
  </div>
  <div mat-dialog-content class="p-3 scrollbar">
    <form [formGroup]="registrationForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ getTranslation$("description") | async }}</mat-label>
            <textarea matInput formControlName="title" rows="4"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-6">
          <mat-form-field appearance="outline" class="w-100 m-right">
            <mat-label>{{ getTranslation$("startDate") | async }}</mat-label>
            <input
              matInput
              [matDatepicker]="startDatePicker"
              formControlName="startDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ getTranslation$("startTime") | async }}</mat-label>
            <input
              matInput
              [ngxTimepicker]="toggleStartTimepicker"
              [format]="24"
              formControlName="startTime"
              [disableClick]="true"
              appTimeInput
            />
            <ngx-material-timepicker-toggle
              matSuffix
              [for]="toggleStartTimepicker"
              class="timepicker-icon"
            ></ngx-material-timepicker-toggle>
            <ngx-material-timepicker
              [format]="24"
              #toggleStartTimepicker
            ></ngx-material-timepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-6">
          <mat-form-field appearance="outline" class="w-100 m-right">
            <mat-label>{{ getTranslation$("endDate") | async }}</mat-label>
            <input
              matInput
              [matDatepicker]="endDatePicker"
              formControlName="endDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="endDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ getTranslation$("endTime") | async }}</mat-label>
            <input
              matInput
              [ngxTimepicker]="toggleEndTimepicker"
              [format]="24"
              formControlName="endTime"
              [disableClick]="true"
              appTimeInput
            />
            <ngx-material-timepicker-toggle
              matSuffix
              [for]="toggleEndTimepicker"
              class="timepicker-icon"
            ></ngx-material-timepicker-toggle>
            <ngx-material-timepicker
              [format]="24"
              #toggleEndTimepicker
            ></ngx-material-timepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <mat-form-field
            (click)="onActivityDropdownClick()"
            class="clickable full-width"
            appearance="outline"
          >
            <mat-label class="clickable">{{
              getTranslation$("activity") | async
            }}</mat-label>
            <mat-select
              appMatSelectLetSpace
              formControlName="timeSort"
              (selectionChange)="onTimeSortChange($event)"
              (openedChange)="timeSortControl.patchValue('')"
              panelClass="dropdown-panel"
            >
              <input
                #timeSortDropdown
                [formControl]="timeSortControl"
                type="text"
                name="filter-options"
                id="filter-options"
                [placeholder]="getTranslation$('searchActivity') | async"
                class="searchBar"
                autocomplete="off"
              />
              <div class="scrollbar">
                <ng-container *ngIf="timeSortControlVal">
                  <mat-option [value]="timeSortFormControl.value"
                    >{{ getTimeSortValue(timeSortControlVal) }}
                  </mat-option>
                </ng-container>
                <mat-option
                  *ngFor="let timeSort of filteredTimeSorts | async"
                  [value]="timeSort.id"
                >
                  {{ getTranslation(timeSort.sort) }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="showSkillsDropdown">
          <mat-form-field
            (click)="onSkillDropdownClick()"
            class="clickable full-width"
            appearance="outline"
          >
            <mat-label class="clickable">{{
              getTranslation$("skill") | async
            }}</mat-label>
            <mat-select
              appMatSelectLetSpace
              formControlName="skill"
              (selectionChange)="onSkillChange($event)"
              (openedChange)="skillControl.patchValue('')"
              panelClass="dropdown-panel"
            >
              <input
                #skillDropdown
                [formControl]="skillControl"
                type="text"
                name="filter-options"
                id="filter-options"
                [placeholder]="getTranslation$('searchSkill') | async"
                class="searchBar"
                autocomplete="off"
              />
              <div class="scrollbar">
                <ng-container *ngIf="skillControlVal">
                  <mat-option [value]="skillFormControl.value"
                    >{{ getSkillValue(skillControlVal) }}
                  </mat-option>
                </ng-container>
                <mat-option
                  *ngFor="let skill of filteredSkills | async"
                  [value]="skill.id"
                >
                  {{ getTranslation(skill.title) }}
                </mat-option>
              </div>
            </mat-select>
            <button
              mat-icon-button
              *ngIf="taskControlVal"
              matSuffix
              aria-label="Clear"
              (click)="clearSelectedSkill()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6" *ngIf="projects?.length ?? 0 > 0">
          <mat-form-field
            (click)="onProjectDropdownClick()"
            class="clickable full-width"
            appearance="outline"
          >
            <mat-label class="clickable">{{
              getTranslation$("project") | async
            }}</mat-label>
            <mat-select
              appMatSelectLetSpace
              formControlName="project"
              (selectionChange)="onProjectChange($event)"
              (openedChange)="projectControl.patchValue('')"
              panelClass="dropdown-panel"
            >
              <input
                #projectDropdown
                [formControl]="projectControl"
                type="text"
                name="filter-options"
                id="filter-options"
                [placeholder]="getTranslation$('searchProject') | async"
                class="searchBar"
                autocomplete="off"
              />
              <div class="scrollbar">
                <ng-container *ngIf="projectControlVal">
                  <mat-option [value]="projectFormControl.value"
                    >{{ getProjectValue(projectControlVal) }}
                  </mat-option>
                </ng-container>
                <mat-option
                  *ngFor="let project of filteredProjects | async"
                  [value]="project.id"
                  class="projects"
                >
                  {{ project.projectId + " " + project.projectName }} <br />
                  {{ project.client?.clientName }}
                </mat-option>
              </div>
            </mat-select>
            <button
              mat-icon-button
              *ngIf="projectControlVal"
              matSuffix
              aria-label="Clear"
              (click)="clearSelectedProject()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="col-md-6" *ngIf="dummyProjects?.length ?? 0 > 0">
          <mat-form-field
            (click)="onDummyProjectDropdownClick()"
            class="clickable full-width"
            appearance="outline"
          >
            <mat-label class="clickable">{{
              getTranslation$("project") | async
            }}</mat-label>
            <mat-select
              appMatSelectLetSpace
              formControlName="dummyProject"
              (selectionChange)="onDummyProjectChange($event)"
              (openedChange)="dummyProjectControl.patchValue('')"
              panelClass="dropdown-panel"
            >
              <input
                #dummyProjectDropdown
                [formControl]="dummyProjectControl"
                type="text"
                name="filter-options"
                id="filter-options"
                [placeholder]="getTranslation$('searchProject') | async"
                class="searchBar"
                autocomplete="off"
              />
              <div class="scrollbar">
                <ng-container *ngIf="dummyProjectControlVal">
                  <mat-option [value]="dummyProjectFormControl.value"
                    >{{ getDummyProjectValue(dummyProjectControlVal) }}
                  </mat-option>
                </ng-container>
                <mat-option
                  *ngFor="let dummyProject of filteredDummyProjects | async"
                  [value]="dummyProject.id"
                >
                  {{ dummyProject.projectNumber + " " + dummyProject.title }}
                </mat-option>
              </div>
            </mat-select>
            <button
              mat-icon-button
              *ngIf="dummyProjectControlVal"
              matSuffix
              aria-label="Clear"
              (click)="clearSelectedDummyProject()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="col-md-6" *ngIf="showTasksDropdown">
          <mat-form-field
            (click)="onTaskDropdownClick()"
            class="clickable full-width"
            appearance="outline"
          >
            <mat-label class="clickable">{{
              getTranslation$("task") | async
            }}</mat-label>
            <mat-select
              appMatSelectLetSpace
              formControlName="task"
              (selectionChange)="onTaskChange($event)"
              (openedChange)="taskControl.patchValue('')"
              panelClass="dropdown-panel"
            >
              <input
                #taskDropdown
                [formControl]="taskControl"
                type="text"
                name="filter-options"
                id="filter-options"
                [placeholder]="getTranslation$('searchTask') | async"
                class="searchBar"
                autocomplete="off"
              />
              <div class="scrollbar">
                <ng-container *ngIf="taskControlVal">
                  <mat-option [value]="taskFormControl.value"
                    >{{ getTaskValue(taskControlVal) }}
                  </mat-option>
                </ng-container>
                <mat-option
                  *ngFor="let task of filteredTasks | async"
                  [value]="task.id"
                >
                  {{ task.title }}
                </mat-option>
              </div>
            </mat-select>
            <button
              mat-icon-button
              *ngIf="taskControlVal"
              matSuffix
              aria-label="Clear"
              (click)="clearSelectedTask()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>

      <div class="row rowbuttons">
        <button
          mat-raised-button
          class="addButton"
          type="submit"
          (click)="onSave()"
          [disabled]="isLoading"
        >
          <mat-icon *ngIf="!isLoading">save</mat-icon>
          <mat-spinner
            *ngIf="isLoading"
            diameter="24"
            color="accent"
          ></mat-spinner>
          <span *ngIf="!isLoading">{{ getTranslation$("save") | async }}</span>
        </button>
      </div>
    </form>
  </div>
</div>
