import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, of, switchMap, tap } from "rxjs";
import { ApiService } from "../../services/api.service";
import { addDummyProject, addDummyProjectResolved, getDummyProjects, getDummyProjectsResolved, removeDummyProjects, removeDummyProjectsResolved, updateDummyProject, updateDummyProjectResolved } from "./dummy-project.actions";

@Injectable({ providedIn: 'root' })
export class DummyProjectEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService
    ) {}

    getDummyProjects = createEffect(() =>
        this.actions$.pipe(
            ofType(getDummyProjects),
            switchMap(({
                callback,
               }) =>
                this.apiService.getDummyProjects().pipe(
                    switchMap((result) => 
                         of(
                          getDummyProjectsResolved({
                            result
                          })
                       )
                    ),
                    tap(() => (callback ? callback() : undefined)),
                    catchError((e) => [])
                )
            )
        )
    );

    addDummyProject = createEffect(() =>
        this.actions$.pipe(
            ofType(addDummyProject),
            switchMap(({ dummyProjects }) =>
                this.apiService.addDummyProject(dummyProjects).pipe(
                    switchMap((addedDummyProjects) => of(addDummyProjectResolved({ addedDummyProjects }))),
                    catchError((e) => [])
                )
            )
        )
    );

    updateDummyProject = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDummyProject),
            switchMap(({ dummyProjects }) => 
                this.apiService.updateDummyProject(dummyProjects).pipe(
                    switchMap((updatedDummyProjects) =>
                        of(updateDummyProjectResolved({ updatedDummyProjects }))
                    ),
                    catchError((e) => [])
                )
            )
        )
    );

    removeDummyProject = createEffect(() =>
        this.actions$.pipe(
            ofType(removeDummyProjects),
            switchMap(({ ishtarTimeDummyProjectIds, callback }) =>
                this.apiService.removeDummyProjects(ishtarTimeDummyProjectIds).pipe(
                switchMap(() =>
                    of(removeDummyProjectsResolved({ishtarTimeDummyProjectIds}))
                ),
                tap(() => (callback ? callback() : undefined)),
                catchError((e) => [])
                )
            )
        )
    );
}