import { Injectable } from '@angular/core';
import { license$ } from '../data/data.observables';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LicenseFacade {
  isAdmin$ = license$.pipe(
    map(
      (licenseInfo) =>
        !!licenseInfo?.licenses?.find((l) => l.productName == 'Ishtar.Time')
          ?.isAdmin
    )
  );

  hasIshtarOoOLicense$ = license$.pipe(
    map(
      (licenseInfo) =>
        !!licenseInfo?.licenses?.find((l) => l.productName == 'Ishtar.OoO')
    )
  );

  hasIshtarProjectsLicense$ = license$.pipe(
    map(
      (licenseInfo) =>
        !!licenseInfo?.licenses?.find((l) => l.productName == 'Ishtar.Projects')
    )
  );
}
