import { BaseEntity } from 'processdelight-angular-components';

export class Task extends BaseEntity {
  title!: string;
  number?: number;
  description?: string;
  projectId!: string;

  constructor(obj: Partial<Task>) {
    super(obj);
    Object.assign(this, obj);
  }
}
