import { Action, createReducer, on } from '@ngrx/store';
import { TimeSort } from '../../domain/models/time-sort';
import {
  addTimeSortResolved,
  getTimeSortsResolved,
  getUserSkillsResolved,
  removeTimeSortsResolved,
  updateTimeSortResolved,
} from './time-sort.actions';
import { AppState } from 'src/app/app.reducer';
import { UserSkill } from '../../domain/models/user-skill';

export const featureSlice = 'timeSort';

export interface State {
  timeSorts: TimeSort[];
  timeSort?: TimeSort;
  ishtarTimeSortIds?: string[];
  pagingCookie: string;
  totalRecordCount: number;
  userSkills: { [userId: string]: UserSkill[] };
}

const defaultState: State = {
  timeSorts: [],
  timeSort: undefined,
  ishtarTimeSortIds: [],
  pagingCookie: '',
  totalRecordCount: 0,
  userSkills: {},
};

export function Reducer(state: State | undefined, action: Action) {
  return timeSortReducer(state, action);
}

export const initialState: State = defaultState;

export const timeSortReducer = createReducer(
  initialState,
  on(getTimeSortsResolved, (state, { result }) => ({
    ...state,
    timeSorts: [...result],
  })),
  on(removeTimeSortsResolved, (state, { ishtarTimeTimesortIds }) => {
    return {
      ...state,
      timeSorts: state.timeSorts.filter(
        (t) => !ishtarTimeTimesortIds.includes(t.id!)
      ),
    };
  }),
  on(updateTimeSortResolved, (state, { updatedTimeSorts }) => ({
    ...state,
    timeSorts: state.timeSorts?.map(
      (t) => updatedTimeSorts.find((s) => t.id === s.id) ?? t
    ),
  })),
  on(addTimeSortResolved, (state, { addedTimeSorts }) => ({
    ...state,
    timeSorts: addedTimeSorts.concat([...(state.timeSorts ?? [])]),
  })),
  on(getUserSkillsResolved, (state, { userId, result }) => ({
    ...state,
    userSkills: {
      ...state.userSkills,
      [userId]: result,
    },
  }))
);

export const timeSortState = (state: AppState) => state.coreFeature.timeSort;
